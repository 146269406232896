<template>
  <div class="day-separator flex justify-center items-center">
    <div class="day bg-white mb-4 rounded px-2 py-1 text-indigo-300 text-xs shadow select-none dark:bg-slate-500 dark:text-white">
      {{ formattedDate }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'DaySeparator',
  props: {
    date: {
      default: null,
      type: String
    }
  },
  computed: {
    formattedDate() {
      let messageDate = dayjs(this.date),
          now = dayjs(),
          difference = now.diff(messageDate, 'days')

      switch (difference) {
        case 0:
          return 'Hoje'
        case 1:
          return 'Ontem'
        case 2:
        case 3:
        case 4:
        case 5:
          return this.$formatDate(messageDate, 'dddd')
        default:
          return this.$formatDate(messageDate, 'DD/MM/YYYY')
      }
    }
  }
}
</script>
