<template>
  <loading v-if="loading"/>
  <div v-else class="flex h-full flex-col">
    <thread-infos :thread="thread"/>
    <thread-messages :thread="thread" :messages="messages"/>
    <thread-footer :thread="thread" @message-sent="resolveSentMessage"/>
  </div>
</template>

<script>
import {orderBy as lodashOrderBy} from 'lodash'
import dayjs from 'dayjs'

import threadsApi from '@/support/api/threads'
import Loading from '@/components/common/Loading'

import ThreadFooter from './components/thread/ThreadFooter'
import ThreadInfos from './components/thread/ThreadInfos'
import ThreadMessages from './components/thread/ThreadMessages'
import DataService from '@/support/services/data'

export default {
  name: 'Thread',
  props: {
    thread: {
      default: null,
      type: Object
    }
  },
  data: () => ({
    messages: [],
    messagesTimer: null,
    loading: true
  }),
  methods: {
    async fetchMessages(data = {}) {
      await threadsApi
          .messages.get(this.thread.id, data)
          .then(({data}) => DataService.insert(this.messages, data))
          .catch((error) => this.$emit('messages-error', error.response.status))

      this.loading = false
    },
    resolveSentMessage(message) {
      DataService.insert(this.messages, message)
    }
  },
  components: {
    Loading,
    ThreadFooter,
    ThreadInfos,
    ThreadMessages
  },
  created() {
    this.fetchMessages()

    this.messagesTimer = setInterval(() => {
      const orderedMessages = lodashOrderBy(this.messages, message => {
        return message.created_at ? dayjs(message.created_at).unix() : 0
      }, ['desc'])

      const lastMessage = orderedMessages.length ? orderedMessages[0] : null

      this.fetchMessages({
        last_message: lastMessage ? lastMessage.created_at : null
      })
    }, parseInt(process.env.VUE_APP_REQUESTS_INTERVAL))
  },
  beforeUnmount() {
    if (this.messagesTimer) clearInterval(this.messagesTimer)
  }
}
</script>
