<template>
  <div class="message flex mb-4 last:mb-0" :class="classes.message">
    <avatar v-if="!message.sender" class="flex-none" :avatar="thread.company.avatar" :text="thread.company.name"/>
    <div class="content max-w-8/12">
      <div class="body mb-2 py-2 px-4 rounded-xl text-slate-600 break-all shadow-md" :class="classes.body" v-html="formattedMessage"/>
      <div class="infos text-xs text-slate-400 text-right">{{ $formatDate(message.created_at, 'HH:mm') }}</div>
    </div>
  </div>
</template>

<script>
import Autolinker from 'autolinker'

import Avatar from '@/components/common/Avatar'

export default {
  name: 'Message',
  props: {
    thread: {
      default: null,
      type: Object
    },
    message: {
      default: null,
      type: Object
    }
  },
  computed: {
    classes() {
      return {
        message: {
          'justify-end': this.isSender
        },
        body: {
          'bg-white': !this.isSender,
          'dark:bg-slate-200': !this.isSender,
          'bg-slate-200': this.isSender,
          'dark:bg-slate-800': this.isSender,
          'dark:text-white': this.isSender,
        }
      }
    },
    formattedMessage() {
      const message = this.message.body

      if (!message) return message

      let replacedMessage = message
          .replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<pre>$1</pre>')
          .replace(/~(~*[^~\n]+~*)~/g, '<del>$1</del>')
          .replace(/_(_*[^_\n]+_*)_/g, '<em>$1</em>')
          .replace(/\*(\**[^*\n]+\**)\*/g, '<b>$1</b>')
          .replace(/\n/g, '<br>')

      return Autolinker.link(replacedMessage)
    },
    isSender() {
      return this.message.sender
    }
  },
  components: {
    Avatar
  }
}
</script>
