import HttpService from '@/support/services/http'

const threadsApi = {
    get: (threadId) => HttpService.get(`threads/${threadId}`),
    messages: {
        get: (threadId, params = {}) => HttpService.get(`threads/${threadId}/messages`, {params}),
        post: (threadId, data) => HttpService.post(`threads/${threadId}/messages`, data)
    }
}

export default threadsApi
