<template>
  <div class="sidebar">
    <history-access/>
  </div>
</template>

<script>
import HistoryAccess from './sidebar/HistoryAccess'

export default {
  name: 'Sidebar',
  components: {
    HistoryAccess
  }
}
</script>
