<template>
  <div class="thread-footer flex bg-white items-center border-t border-slate-200 py-2 pr-2 pl-5 dark:bg-slate-700 dark:border-slate-500">
    <textarea v-model="message" ref="textarea"
              class="bg-slate-50 w-full mr-2 border rounded-xl py-2 px-4 max-h-24 text-slate-600 resize-none focus:outline-none focus:border-indigo-300 dark:bg-slate-600 dark:border-slate-500 dark:text-slate-100 dark:focus:border-indigo-300"
              rows="1" placeholder="Digite uma mensagem..." autofocus @keypress.enter.shift.exact.stop="breakLine"
              @keydown.enter.exact.prevent="submitMessage"/>
    <button
        class="flex flex-none rounded-full w-12 h-12 items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        type="button" :disabled="!canSendMessage" :title="submitButtonTitle" @click.prevent="submitMessage">
      <svg class="block w-7 fill-current text-indigo-500 dark:text-indigo-400" x="0px" y="0px" viewBox="0 0 26 23">
        <path
            d="M1.072,22.539l24.464-10.531c0.618-0.266,0.618-1.147,0-1.413L1.072,0.063C0.564-0.155,0,0.219,0,0.773v7.256c0,0.387,0.285,0.715,0.668,0.766l18.744,2.506L0.667,13.815C0.285,13.866,0,14.193,0,14.581v7.248C0,22.383,0.564,22.757,1.072,22.539z M0,23"/>
      </svg>
    </button>
  </div>
</template>

<script>
import threadsApi from '@/support/api/threads'

export default {
  name: 'ThreadFooter',
  props: {
    thread: {
      default: null,
      type: Object
    }
  },
  data: () => ({
    message: null
  }),
  computed: {
    canSendMessage() {
      return !!this.message
    },
    submitButtonTitle() {
      return this.canSendMessage ?
          'Clique aqui para enviar a mensagem' :
          'Escreva uma mensagem para poder enviá-la'
    }
  },
  methods: {
    breakLine() {
      this.message = `${this.message}`
    },
    resolveTextareaHeight() {
      const element = this.$refs.textarea

      const offset = element.offsetHeight - element.clientHeight

      element.style.height = 'auto'
      element.style.height = `${element.scrollHeight + offset}px`
      element.scrollTop = element.scrollHeight
    },
    async submitMessage() {
      if (this.canSendMessage)
        await threadsApi
            .messages.post(this.thread.id, {body: this.message})
            .then(({data}) => {
              this.$emit('message-sent', data)

              this.message = null

              setTimeout(() => this.resolveTextareaHeight(), 10)
            })
    }
  },
  watch: {
    message() {
      this.resolveTextareaHeight()
    }
  }
}
</script>
