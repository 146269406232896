<template>
  <div class="relative bg-indigo-300 flex mr-4 rounded-full w-11 h-11 select-none overflow-hidden items-center justify-center">
    <div v-if="avatar" class="absolute bg-cover inset-0 w-full h-full" :style="avatarStyles"/>
    <div v-if="text" class="text-white font-medium">{{ initials }}</div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    avatar: {
      default: null,
      type: String
    },
    text: {
      default: null,
      type: String
    }
  },
  computed: {
    avatarStyles() {
      return {
        'background-image': this.avatar ? `url('${this.avatar}')` : null
      }
    },
    initials() {
      if (!this.text) return '?'

      let words = []

      const generateInitials = (origin) => origin.forEach(word => {
        if (/[a-zA-Z0-9]/.test(word.charAt(0))) words.push(word)
      })

      generateInitials(this.text.split(/, |,|[.]| |-/))

      if (words.length === 1) return words[0].slice(0, 2)

      return words.map(word => word.charAt(0)).slice(0, 2).join('')
    }
  }
}
</script>
