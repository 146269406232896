<template>
  <div class="thread-messages bg-slate-50 flex-1 p-6 overflow-auto dark:bg-slate-600" ref="messages">
    <div v-if="this.messages.length" class="messages" key="messages">
      <div v-for="(dayGroup, day) in dayGroupedMessages" class="day-group" :key="day">
        <day-separator :date="day"/>
        <div class="message-groups">
          <div v-for="messageGroup in dayGroup" class="message-group" :key="messageGroup">
            <message v-for="message in messageGroup" :thread="thread" :message="message" :key="message.id"/>
          </div>
        </div>
      </div>
    </div>
    <status-indicator v-else subtitle="Esta conversa ainda não possui mensagens." key="no-messages"/>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  forEach as lodashForEach,
  groupBy as lodashGroupBy,
  orderBy as lodashOrderBy
} from 'lodash'

import StatusIndicator from '@/components/common/StatusIndicator'

import DaySeparator from './DaySeparator'
import Message from './Message'

export default {
  name: 'ThreadMessages',
  props: {
    messages: {
      default: () => ([]),
      type: Array
    },
    thread: {
      default: null,
      type: Object
    }
  },
  computed: {
    dayGroupedMessages() {
      let sortedMessages = lodashOrderBy(this.messages, (message) => {
        return dayjs(message.created_at).unix()
      }, ['asc'])

      let dayGroupedMessages = lodashGroupBy(sortedMessages, (message) => {
        return dayjs(message.created_at).startOf('day')
      })

      lodashForEach(dayGroupedMessages, (data, day) => {
        let messages = data

        let groups = []

        messages.forEach((message) => {
          if (!groups.length) {
            groups.push([message])
          } else {
            let lastGroup = groups[groups.length - 1]

            lastGroup.push(message)
          }
        })

        dayGroupedMessages[day] = groups
      })

      return dayGroupedMessages
    }
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.messages

      container.scrollTop = container.scrollHeight
    }
  },
  components: {
    StatusIndicator,
    DaySeparator,
    Message
  },
  mounted() {
    this.scrollToBottom()
  },
  updated() {
    this.$nextTick(() => this.scrollToBottom())
  }
}
</script>
