<template>
  <div class="status-indicator flex h-full items-center justify-center flex-col select-none">
    <div class="icon mb-4">
      <svg class="block fill-current text-slate-300 dark:text-slate-200" x="0px" y="0px" viewBox="0 0 40 40">
        <path
            d="M34.3,0H5.7C2.6,0,0,2.6,0,5.7v28.6C0,37.4,2.6,40,5.7,40h28.6c3.2,0,5.7-2.6,5.7-5.7V5.7C40,2.6,37.4,0,34.3,0z M5.7,1.9h28.6c2.1,0,3.8,1.7,3.8,3.8v15.7h-6.2c-0.3,0-0.5,0.1-0.7,0.3l-6.9,6.9h-8.7l-6.9-6.9c-0.2-0.2-0.4-0.3-0.7-0.3H1.9V5.7C1.9,3.6,3.6,1.9,5.7,1.9z M34.3,38.1H5.7c-2.1,0-3.8-1.7-3.8-3.8v-11h5.8l6.9,6.9c0.2,0.2,0.4,0.3,0.7,0.3h9.5c0.3,0,0.5-0.1,0.7-0.3l6.9-6.9h5.8v11C38.1,36.4,36.4,38.1,34.3,38.1z"/>
        <path
            d="M14.6,10.3c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4h0c-0.8,0-1.4,0.6-1.4,1.4C13.2,9.7,13.8,10.3,14.6,10.3z"/>
        <path
            d="M25.4,10.3c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4h0c-0.8,0-1.4,0.6-1.4,1.4C24,9.7,24.6,10.3,25.4,10.3z"/>
        <path v-if="error"
            d="M14,19.7c0.4,0.4,1,0.4,1.3,0c1.2-1.2,2.8-1.9,4.5-1.9c0,0,0,0,0.1,0c1.7,0,3.3,0.7,4.5,1.8l0.1,0.1c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.3l-0.1-0.1c-1.6-1.6-3.7-2.4-5.9-2.4c-2.2,0-4.3,0.9-5.9,2.5C13.7,18.8,13.7,19.4,14,19.7z"/>
        <path v-else
            d="M26,16.1c-0.4-0.4-1-0.4-1.3,0c-1.2,1.2-2.8,1.9-4.5,1.9c0,0,0,0-0.1,0c-1.7,0-3.3-0.7-4.5-1.8l-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2,0-0.5,0.1-0.7,0.3c-0.4,0.4-0.4,1,0,1.3l0.1,0.1c1.6,1.6,3.7,2.4,5.9,2.4c2.2,0,4.3-0.9,5.9-2.5C26.3,17.1,26.3,16.5,26,16.1z"/>
      </svg>
    </div>
    <div class="title mb-1 text-slate-500 text-2xl font-semibold dark:text-slate-100">{{ title }}</div>
    <div v-if="subtitle" class="subtitle mb-4 text-slate-400 dark:text-slate-200" v-html="subtitle"/>
  </div>
</template>

<script>
export default {
  name: 'StatusIndicator',
  props: {
    error: {
      default: true,
      type: Boolean
    },
    title: {
      default: 'Ooops!',
      type: String
    },
    subtitle: {
      default: null,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.status-indicator {
  svg {
    width: 50px;
    height: 50px;
  }
}
</style>
