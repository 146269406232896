<template>
  <div class="loading flex h-full items-center justify-center">
    <div class="spinner border-4 border-indigo-100 rounded-full w-11 h-11 dark:border-indigo-50"/>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss" scoped>
.loading {
  .spinner {
    border-top-color: #6366F1;

    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.55, 0.15, 0.45, 0.85);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
