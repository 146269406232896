<template>
  <div class="thread-infos bg-white p-4 border-b border-slate-200 shadow-sm dark:bg-slate-700 dark:border-slate-500">
    <div class="name text-slate-600 text-lg font-medium dark:text-slate-100">{{ thread.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'ThreadInfos',
  props: {
    thread: {
      default: null,
      type: Object
    }
  }
}
</script>
