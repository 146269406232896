const PageService = {
    resetTitle() {
        this.setTitle(process.env.VUE_APP_NAME)
    },
    setTitle(text) {
        document.title = text
    }
}

export default PageService
