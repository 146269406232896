<template>
  <div class="my-4 text-slate-700 text-lg font-medium dark:text-slate-100">Histórico de acessos</div>
  <div v-for="(threadsGroup, company) in groupedThreads" class="company-group mb-3 last:mb-0" :key="company">
    <div class="company-name mb-1 text-slate-500 font-medium dark:text-slate-100">{{ company }}</div>
    <ul class="ml-4">
      <li v-for="thread in threadsGroup" :key="thread.access_key">
        <router-link class="text-slate-500 break-all hover:text-indigo-500 dark:text-slate-200 dark:hover:text-slate-700"
                     :to="{name: 'home', params: {thread: thread.access_key}}">{{ thread.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {groupBy as lodashGroupBy} from 'lodash'

export default {
  name: 'HistoryAccess',
  computed: {
    ...mapGetters({
      threadsGetter: 'threads/threads'
    }),
    groupedThreads() {
      return lodashGroupBy(this.threadsGetter, thread => thread.company.name)
    }
  },
  mounted() {
    console.log(this.groupedThreads)
  }
}
</script>
