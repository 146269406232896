<template>
  <div class="flex gap-6 h-full">
    <div class="flex-none w-1/5 hidden xl:block">
      <sidebar/>
    </div>
    <div class="flex-auto">
      <div class="dark:bg-slate-600 h-full overflow-hidden md:bg-slate-50 md:rounded-xl md:shadow-lg">
        <loading v-if="loading" key="loading"/>
        <thread v-else-if="thread" :thread="thread" key="thread" @messages-error="resolveMessagesError"/>
        <status-indicator v-else :error="!!threadStatus" :title="threadStatusTitle" :subtitle="threadStatusSubtitle"
                          key="no-thread"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import threadsApi from '@/support/api/threads'
import PageService from '@/support/services/page'

import Loading from '@/components/common/Loading'
import StatusIndicator from '@/components/common/StatusIndicator'

import Sidebar from './components/home/Sidebar'
import Thread from './components/home/Thread'

export default {
  name: 'Home',
  data: () => ({
    loading: true,
    thread: null,
    threadStatus: null
  }),
  computed: {
    threadId() {
      return this.$route.params.thread
    },
    threadStatusTitle() {
      switch (this.threadStatus) {
        case null:
          return 'Oi! Tudo bem?'
        default:
          return 'Ooops!'
      }
    },
    threadStatusSubtitle() {
      switch (this.threadStatus) {
        case 401:
          return `Não encontramos a conversa <span class="text-slate-500 font-medium dark:text-slate-100">${this.threadId}</span>.`
        case null:
          return 'As suas conversas irão aparecer aqui.'
        default:
          return 'Algum erro inesperado ocorreu.'
      }
    }
  },
  methods: {
    ...mapActions({
      saveThreadAction: 'threads/saveThread',
      deleteThreadAction: 'threads/deleteThread'
    }),
    async fetchThread() {
      if (this.threadId)
        await threadsApi
            .get(this.threadId)
            .then(({data}) => {
              this.thread = data

              PageService.setTitle(`${this.thread.company.name} - ${this.thread.name}`)

              this.saveThreadAction(this.thread)
            })
            .catch((error) => {
              this.threadStatus = error.response.status

              this.deleteThreadAction({
                access_key: this.threadId
              })
            })

      this.loading = false
    },
    resolveMessagesError(error) {
      this.threadStatus = error
    }
  },
  components: {
    Loading,
    StatusIndicator,
    Sidebar,
    Thread
  },
  created() {
    setTimeout(() => this.fetchThread(), 500)
  }
}
</script>
